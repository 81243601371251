import * as React from 'react';
import { Container } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import Seo from '../components/Seo';
import Banner from '../components/Banner';
import BannerMobile from '../components/BannerMobile';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { LocationLanguageContextType, StrapiHomePage } from '../typings';
import { Languages } from '../utils/getAllSupportedLanguages';
import NovadeLiteSection from '../components/HomeNovadeLiteSection';
import CategorySection from '../components/CategorySection';
import { matchesMobile } from '../utils/mediaQuery';
import FeaturedTemplatesSection from '../components/FeaturedTemplatesSection';

const strapiHomePageQuery = graphql`
  query IndexLayoutQuery {
    allStrapiHomePage {
      nodes {
        Title
        FeaturedTemplatesLabel
        BrowseByCategoryLabel
        ViewMoreLabel
        NLSectionTitle
        NLSectionSubtitle
        SignUpLabel
        SignUpUrl
        locale
      }
    }
  }
`;

const IndexLayout: React.FC = () => {
  const data = useStaticQuery(strapiHomePageQuery);
  const allStrapiHomePage: StrapiHomePage[] = data.allStrapiHomePage.nodes;
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const homePageContent = allStrapiHomePage.find((x) => x.locale === language);
  const isMobile = matchesMobile();

  if (homePageContent) {
    return (
      <>
        <Seo title='Home' isTemplatePage={false} />
        {isMobile ? <BannerMobile /> : <Banner />}
        <Container maxWidth='xl'>
          <CategorySection header={homePageContent.BrowseByCategoryLabel} />
          <FeaturedTemplatesSection homePageContent={homePageContent} />
          <NovadeLiteSection
            title={homePageContent.NLSectionTitle}
            subtitle={homePageContent.NLSectionSubtitle}
            signupLabel={homePageContent.SignUpLabel}
            signupLink={homePageContent.SignUpUrl}
            locale={language}
          />
        </Container>
      </>
    );
  }

  return null;
};

export default IndexLayout;
